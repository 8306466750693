import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import "./Dashboard.css";
function ResultDashboard({ resData, voteCount, voterCount, distResData, districtVoterCount }) {
  const zoneMapping = {
    Z1: "Zone 1",
    Z2: "Zone 2",
    Z3: "Zone 3",
    Z4: "Zone 4",
    Z5: "Zone 5",
    Z6: "Zone 6",
    Z7: "Zone 7",
    Z8: "Zone 8",
  };
  const categoryMapping = {
    'Small Scale Manufacturing Activity': 'MS',
    'Large Scale Manufacturing Activity': 'ML',
    'Small Scale Trading Activity': 'TS',
    'Large Scale Trading Activity': 'TL',
    'Small Scale Service Activity': 'SS',
    'Large Scale Service Activity': 'SL',
    'Profession': 'PR',
    // 'District Chambers Of Commerce': 'DC',
    'Association': 'AS'
  };
  
  const updatedResData = [];

resData.forEach((item) => {
  if (item.industry !== 'District Chambers Of Commerce') {
    item.total_votes = Number(item.total_votes);
    item.total_voters = Number(item.total_voters);
    console.log(item.industry, categoryMapping[item.industry]);
    console.log(item.industry === categoryMapping[item.industry]);
    if (categoryMapping[item.industry]) {
      item.indsutryShort = categoryMapping[item.industry];
    }
    updatedResData.push(item);
  }
});
  distResData.forEach((item)=>{
    item.total_voters = Number(item.total_voters);
    if (zoneMapping[item.zone]) {
      item.zone = zoneMapping[item.zone];
    }
  });
  console.log(distResData, 'checkingsudgd');
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" />
        <Column footer={voteCount} />
      </Row>
    </ColumnGroup>
  );
  const footerGroup2 = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" />
        <Column footer={districtVoterCount} />
      </Row>
    </ColumnGroup>
  );
  const COLORS = [
    "#007acc", // Blue
    "#47a447", // Green
    "#f0ad4e", // Orange
    "#d9534f", // Red
    "#5bc0de", // Light Blue
    "#5cb85c", // Dark Green
    "#f0a76e", // Peach
    "#d66d75", // Rose
    "#7da7d9", // Sky Blue
  ];
  const renderZone = (rowData) => {
    switch (rowData.zone) {
      case 'Zone 1':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Bagalkot, Belgaum, Uttara Kannada</span>
          </p>
        );
      case 'Zone 2':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Gulbarga, Bidar, Bijapur</span>
          </p>
        );
      case 'Zone 3':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Raichur, Koppal, Yadgir</span>
          </p>
        );
      case 'Zone 4':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Dharwad, Haveri, Gadag</span>
          </p>
        );
      case 'Zone 5':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Shimoga, Bellary, Davanagere, Chitradurga</span>
          </p>
        );
      case 'Zone 6':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Mandya, Mysore, Chamarajnagar, Hassan</span>
          </p>
        );
      case 'Zone 7':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Dakshina Kannada, Udupi, Kodagu, Chickmagalur</span>
          </p>
        );
      case 'Zone 8':
        return (
          <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {rowData.zone}
            <span>Bangalore Rural, Ramanagar, Chikaballapur, Tumkur, Kolar</span>
          </p>
        );
      default:
        return rowData.zone;
    }
  };

  return (
    <>
    <div class="flex w-12">
      <div class="pl-5 my-3 w-6">
        <div class="voter-box">
          <h4>Total Voters:</h4>
          <h4>{voterCount}</h4>
        </div>
        <DataTable
          showGridlines
          value={resData}
          footerColumnGroup={footerGroup}
        >
          <Column field="industry" header="Industry"></Column>
          <Column field="total_votes" header="Votes"></Column>
        </DataTable>
      </div>
      <div className="w-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',marginTop:40 }}>
        <div>
            <h3>Voters by Sector</h3>
          <PieChart  width={600} height={450}>
            <Pie
              data={updatedResData}
              dataKey="total_voters"
              nameKey="indsutryShort"
              cx="50%"
              cy="50%"
              outerRadius={180}
              fill="#8884d8"
            >
              {updatedResData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value, name) => [value, name]} />
            <Legend/>
          </PieChart>
        </div>
       
      </div>
    </div>
    <div class="flex w-12">
      <div class="pl-5 my-3 w-6">
      <h3 class="pb-3">District Chambers</h3>
      <DataTable
          showGridlines
          value={distResData}
          footerColumnGroup={footerGroup2}
        >
          <Column field="zone" header="Zone No" body={renderZone} ></Column>
          <Column field="total_voters" header="Votes"></Column>
        </DataTable>
      </div>
      <div className="w-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',marginTop:40 }}>

      <div style={{marginTop:40}}>
            <h3>Voters on District Chambers</h3>
          <PieChart width={600} height={450}>
            <Pie
              data={distResData}
              dataKey="total_voters"
              nameKey="zone"
              cx="50%"
              cy="50%"
              outerRadius={180}
              fill="#8884d8"
            >
              {distResData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value, name) => [value, name]} />
            <Legend/>
          </PieChart>
        </div>
        </div>
      </div>
    </>
  );
}

export default ResultDashboard;
