import { Button } from "primereact/button";
import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";

function UserDetails({ id }) {
  const toast = useRef(null);
  const componentRef = useRef();
  const [code, setCode] = useState("");
  const [displayBasic1, setDisplayBasic1] = useState(false);
  const [data, setData] = useState(null);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [displayBasic3, setDisplayBasic3] = useState(false);
  const [displayBasic4, setDisplayBasic4] = useState(false);

  const [representative1Image, setRepresentative1Image] = useState(null);
  const [representative2Image, setRepresentative2Image] = useState(null);
  const [representative3Image, setRepresentative3Image] = useState(null);
  const [id1, setId1] = useState(null);
  const [id2, setId2] = useState(null);
  const [id3, setId3] = useState(null);

  const [rfid1, setRfid1] = useState("");
  const [rfid2, setRfid2] = useState("");
  const [rfid3, setRfid3] = useState("");

  const [Email, setEmail] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [codeData, setCodeData] = useState(null);
  const[paid,setPaid] = useState(false);

  // const [rfid1, setRfid1] = useState("");
  const [index, setIndex] = useState(null);
  const [position, setPosition] = useState("center");
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const navigate = useNavigate();
  const dialogFuncMap = {
    displayBasic1: setDisplayBasic1,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const styles = {
    width: "300px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "2px",
    textAlign: "left",
    marginTop: "2px",
  };
  const styles2 = {
    width: "250px",
    border: "1px solid #000000",
    borderRadius: "0px",
    marginLeft: "30px",
    textAlign: "left",
    marginTop: "",
  };
  const memberOptions = ["ordinary", "patron"];
  const statusOptions = ["Active", "Deactive"];
  const membershipCategory = [
    "Small Scale Manufacturing Activity",
    "Large Scale Manufacturing Activity",
    "Small Scale Trading Activity",
    "Large Scale Trading Activity",
    "Small Scale Service Activity",
    "Large Scale Service Activity",
    "District Chambers Of Commerce",
    "Association",
    "Profession",
  ];
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "There is an Error, to update image. Please Try Again",
      life: 3000,
    });
  };
  const updateMember = async (data, id) => {
    console.log('res',data,id)
    try {
      const response =
     await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/rfid/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
   if(!data.hasOwnProperty('hasPaid')){
    toast.current.show({
      severity: "success",
      summary: "RFID Updated!",
      life: 5000,
    });
   }else{
    toast.current.show({
      severity: "success",
      summary: "Payment status Updated!",
      life: 5000,
    });
   }
      setRfid1("")
      setRfid2("")
      setRfid3("")

    }  catch (error) {
      if (error.response.status === 401) {
        try {
          const refreshResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
            {
              refreshToken: window.localStorage.getItem("refreshToken"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("token", refreshResponse?.data?.accessToken);
          const newToken = refreshResponse?.data?.accessToken;
          window.localStorage.setItem("token", newToken);
          return updateMember(data,id);
        } catch (refreshError) {
          window.location.replace("/");
          return error;
        }
      } else{
        // toast.current.show({
        //     severity: "error",
        //     summary: "Error",
        //     detail: error?.response?.data?.err?.data,
        //     life: 3000,
        //   });
      }
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      const result = response?.data;

      if (result?.err) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: result?.err?.data,
          life: 3000,
        });
      } else {
        console.log("res", result);
        setData(result);
        setPaid(result?.has_paid)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const refreshResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
            {
              refreshToken: window.localStorage.getItem("refreshToken"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("token", refreshResponse?.data?.accessToken);
          const newToken = refreshResponse?.data?.accessToken;
          window.localStorage.setItem("token", newToken);
          return getData();
        } catch (refreshError) {
          window.location.replace("/");
          return null;
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.response?.data?.err?.data,
          life: 3000,
        });
      }
    }
  };
  useEffect(() => {
    // setValue()
    getData();
  }, [id]);
  console.log("data", data);
  return (
    <div>
      <Toast ref={toast}></Toast>
      <div className="form">
        <form className="card-container text-left">
          <div className="flex flex-row justify-content-evenly align-items-start p-4">
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    ID Number :
                  </div>
                  <InputText style={styles} value={data?.MEM_id_no}></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Date of Admission:
                  </div>
                  <Calendar
                    style={styles}
                    value={new Date(data?.date_of_admission)}
                    dateFormat="dd-mm-yy"
                  />
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Member Category :
                  </div>
                  <Dropdown
                    style={styles}
                    value={data?.member_category}
                    options={memberOptions}
                  ></Dropdown>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Date of Establishment:
                  </div>
                  <Calendar
                    style={styles}
                    value={new Date(data?.date_of_estd)}
                    dateFormat="dd-mm-yy"
                  />
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Membership Category :
                  </div>
                  <Dropdown
                    style={styles}
                    value={data?.membership_category}
                    options={membershipCategory}
                  ></Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-row justify-content-start align-items-center px-4"
            style={{ marginBottom: "-7px" }}
          >
            <div className=" flex text-right p-1">
              <div
                className="text-l align-items-center textInput"
                style={{ width: "235px" }}
              >
                Company Name :
              </div>
              <InputText
                style={{
                  width: "725px",
                  border: "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                }}
                value={data?.name_of_org ?? ""}
              ></InputText>
            </div>
          </div>
          <div className="flex flex-row justify-content-evenly align-items-start px-4">
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Address :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.address ?? ""}
                  ></InputText>
                </div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Place :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.city ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Pincode :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.pincode ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Telephone No. :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.user?.phone_number ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Email ID :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.user?.email ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Website :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.website ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Fax :
                  </div>
                  <InputText style={styles} value={data?.fax ?? ""}></InputText>
                </div>
                <div className="flex" style={{ marginTop: "150px" }}>
                  <div className="text-l align-items-center textInput">
                    GST :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.GSTIN ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    PAN :
                  </div>
                  <InputText style={styles} value={data?.PAN ?? ""}></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    CIN No. :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.cin_no ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Product Description :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.product_desc ?? ""}
                  ></InputText>
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div className="flex" style={{ marginTop: "0px" }}>
                  <div className="text-l align-items-center textInput">
                    Representative 1 Name:
                  </div>
                  <InputText
                    style={styles}
                    value={data?.name_of_representative1 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.designation_of_representative1 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    id="mobile"
                    value={data?.mobile ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Representative 2 Name:
                  </div>
                  <InputText
                    style={styles}
                    id="name_of_representative2"
                    value={data?.name_of_representative2 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    id="designation_of_representative2"
                    value={data?.designation_of_representative2 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.mobile_of_repr2 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Representative 3 Name:
                  </div>
                  <InputText
                    style={styles}
                    value={data?.name_of_representative3 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Designation :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.designation_of_representative3 ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.mobile_of_repr3 ?? ""}
                  ></InputText>
                </div>
                <div className="flex" style={{ marginTop: "50px" }}>
                  <div className="text-l align-items-center textInput">
                    Annual Turnover :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.annual_turnover ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Proposed By :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.proposed_by ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Seconded By :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.seconded_by ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Referred By :
                  </div>
                  <InputText
                    style={styles}
                    id="referred_by"
                    value={data?.referred_by ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Amount Collected :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.amount_collected ?? ""}
                  ></InputText>
                </div>
              </div>
            </div>
            <div className="w-4 text-right p-1">
              <div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "5px" }}
                >
                  {data?.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative1Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref1}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img1"
                    type={"file"}
                    onChange={(e) => {
                      // setRepresentative1Image(e.target.files);
                      // uploadImages(e.target.files[0], "representative1");
                      e.target.value = null;
                    }}
                  />
                  <InputText
                    style={styles2}
                    placeholder="RFID"
                    type="number"
                    value={rfid1}
                    onChange={(e) => setRfid1(e.target.value)}
                  />
                  <i
                    className="pi pi-check "
                    style={{
                      fontSize: "1.5rem",
                      marginLeft: "",
                      backgroundColor: "#3B82F6",
                      color: "white",
                      padding: "11px",
                    }}
                    onClick={async() => {
                      if (rfid1.length === 10) {
                        let val = { rfid: rfid1 };
                        updateMember(val, data?.id)
                        
                      } else {
                        toast.current.show({
                          severity: "error",
                          summary: "Error",
                          detail: "RFID must be 10 digit",
                          life: 3000,
                        });
                      }
                    }}
                  ></i>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  {data?.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative2Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref2}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img2"
                    type={"file"}
                    onChange={(e) => {
                      console.log("event", e.target);
                      // uploadImages(e.target.files[0], "representative2");
                      e.target.value = null;
                    }}
                  />
                  <InputText
                    style={styles2}
                    placeholder="RFID"
                    type="number"
                    value={rfid2}
                    onChange={(e) => setRfid2(e.target.value)}
                  />
                  <i
                    className="pi pi-check "
                    style={{
                      fontSize: "1.5rem",
                      marginLeft: "",
                      backgroundColor: "#3B82F6",
                      color: "white",
                      padding: "11px",
                    }}
                    onClick={() => {
                      if (rfid2.length === 10) {
                        let val = { rfid2: rfid2 };
                        updateMember(val, data?.id)
                      } else {
                        toast.current.show({
                          severity: "error",
                          summary: "Error",
                          detail: "RFID must be 10 digit",
                          life: 3000,
                        });
                      }
                    }}
                  ></i>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: "10px" }}
                >
                  {data?.length !== 0 && data !== null ? (
                    <img
                      src={
                        representative3Image ??
                        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                      }
                      alt="user"
                      height={"138px"}
                      width={"120px"}
                    />
                  ) : null}
                  <input
                    ref={ref3}
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      textAlign: "left",
                      marginTop: "2px",
                      marginLeft: "20px",
                      display: "none",
                    }}
                    id="img2"
                    type={"file"}
                    onChange={(e) => {
                      // uploadImages(e.target.files[0], "representative3");
                      e.target.value = null;
                    }}
                  />
                         <InputText
                    style={styles2}
                    placeholder="RFID"
                    type="number"
                    value={rfid3}
                    onChange={(e) => setRfid3(e.target.value)}
                  />
                  <i
                    className="pi pi-check "
                    style={{
                      fontSize: "1.5rem",
                      marginLeft: "",
                      backgroundColor: "#3B82F6",
                      color: "white",
                      padding: "11px",
                    }}
                    onClick={() => {
                      if (rfid3.length === 10) {
                        let val = { rfid3: rfid3 };
                        console.log(val, "nulll");
                        updateMember(val, data?.id)
                      } else {
                        toast.current.show({
                          severity: "error",
                          summary: "Error",
                          detail: "RFID must be 10 digit",
                          life: 3000,
                        });
                      }
                    }}
                  ></i>
                </div>
                <div className="flex" style={{ marginTop: "54px" }}>
                  <div className="text-l align-items-center textInput">
                    Introducer Name :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.introducer_name ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Mobile No. of Introducer :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.introducer_mobile_no ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div
                    className="text-l align-items-center textInput"
                    style={{ height: "45px", margin: "2px 0px" }}
                  >
                    Status :
                  </div>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "45px",
                    }}
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="ingredient1"
                        name="Active"
                        value={true}
                        checked={data?.user?.is_active}
                      />
                      <label htmlFor="ingredient1" className="ml-2">
                        Active
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="ingredient2"
                        name="Deactive"
                        value={false}
                        checked={!data?.user?.is_active}
                      />
                      <label htmlFor="ingredient2" className="ml-2">
                        Deactive
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div
                    className="text-l align-items-center textInput"
                    style={{ height: "45px", margin: "2px 0px" }}
                  >
                    Documents Submitted :
                  </div>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "45px",
                    }}
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="3"
                        // name="Yes"
                        value={true}
                        checked={data?.documents_submitted}
                      />
                      <label htmlFor="3" className="ml-2">
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="4"
                        // name="No"
                        value={false}
                        checked={!data?.documents_submitted}
                      />
                      <label htmlFor="4" className="ml-2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="text-l align-items-center textInput">
                    Remark :
                  </div>
                  <InputText
                    style={styles}
                    value={data?.remarks ?? ""}
                  ></InputText>
                </div>
                <div className="flex">
                  <div
                    className="text-l align-items-center textInput"
                    style={{ height: "45px", margin: "2px 0px" }}
                  >
                    Payment Status :
                  </div>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "45px",
                    }}
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="5"
                        checked={paid}
                        onChange={e=>{
                          setPaid(true)
                        }}
                      />
                      <label htmlFor="5" className="ml-2">
                        Paid
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="6"
                        checked={!paid}
                        onChange={e=>{
                          setPaid(false)
                        }}
                      />
                      <label htmlFor="6" className="ml-2">
                        Not Paid
                      </label>
                    </div>
                  </div>
                </div>
              </div>
         
            </div>
        
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "100px",
              marginTop: "30px",
            }}
          >
            <Button
              className="w-15rem m-2"
              type="button"
              onClick={() => {
                const val = {
                  hasPaid:paid
                }
                updateMember(val,data?.id)
              }}
              label="Save"
            ></Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserDetails;
