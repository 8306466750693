import Login from "./pages/Login";
import "primeflex/primeflex.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import AdminAuthGuard from "./guards/AdminAuthGuard";
import Election from "./site/admin/Election/Election";
import ScanRfid from "./site/admin/ScanRfid/ScanRfid";
import Results from "./site/admin/Results/Results";
import Popup from "./site/admin/Election/components/Popup";
import CandidatesTable from "./site/admin/Election/components/CandidatesTable";
import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import SearchUser from "./site/admin/SearchUser/SearchUser";
import ActivityFeed from "./site/admin/ActivityFeed/ActivityFeed";
const customHistory = createBrowserHistory();
const App = () => {
  const { token } = useSelector((state) => state.member);
  const logOut = () => {
    setTimeout(() => {
      window.location.replace("/");
      window.localStorage.clear();
    }, 43200000);
  };
  const userType = window.localStorage.getItem("userType");
  console.log(userType, "kjhgfghjkl");
  useEffect(() => {
    console.log("token", token);
    logOut();
  }, [token]);
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<Login />}></Route>
        {(userType === "Super Admin" ||
          userType === "Election Scan") && (
          <Route
            path="scanRfid"
            element={<AdminAuthGuard children={<ScanRfid />} />}
          ></Route>
        )}
         {(userType === "Super Admin" ||
          userType === "Election RFID Update User") && (
          <Route
            path="searchUser"
            element={<AdminAuthGuard children={<SearchUser />} />}
          ></Route>
        )}
          {(userType === "Super Admin" ||
          userType === "Election Activity User") && (
          <Route
            path="activityFeed"
            element={<AdminAuthGuard children={<ActivityFeed />} />}
          ></Route>
        )}
        {(userType === "Super Admin" || userType === "Election Result") && (
          <Route
            path="results"
            element={<AdminAuthGuard children={<Results />} />}
          ></Route>
        )}
        {(userType === "Super Admin" || userType === "Election Vote") && (
          <Route
            path="election"
            element={<AdminAuthGuard children={<Election />} />}
          >
            <Route
              path="accessCode"
              element={<AdminAuthGuard children={<Popup />} />}
            ></Route>
            <Route
              path="vote"
              element={<AdminAuthGuard children={<CandidatesTable />} />}
            ></Route>
          </Route>
        )}
      </Routes>

      <Routes>
        {/* Admin Routes */}

        {/* <Route
          path="/adminLogin"
          element={<AdminAuthGuard children={<AdminLogin />} />}
        ></Route> */}
        {/* Members Routes */}
      </Routes>
    </div>
  );
};

export default App;
