import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AdminMenuBar from "../AdminMenuBar";
import { Divider } from "primereact/divider";
import MemberProfile from "../UserManagement/components/MemberDetails/components/MemberProfile";
import useFetch from "../../../Hooks/useFetch";
import { Toast } from "primereact/toast";
import axios from "axios";
import MemberDescription from "../ScanRfid/components/MemberDescription";
import UserDetails from "./components/UserDetails";

function SearchUser() {
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const toast = useRef(null);
  const [accessCode, setAccessCode] = useState("");
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState(null);

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  // const { data, loading, err, statusCode } = useFetch(
  //   "GET",
  //   `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${rfid}`,
  //   update
  // );
  const searchRFID = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/search/members?key=${id}`,
        headers
      )
      .then((res) => {
        console.log("res", res?.data[0]?.member_id);
        setData(res?.data);
        setId2(res?.data[0]?.member_id);
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          try {
            const refreshResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
              { refreshToken: window.localStorage.getItem("refreshToken") },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("token", refreshResponse?.data?.accessToken);
            const newToken = refreshResponse?.data?.accessToken;
            window.localStorage.setItem("token", newToken);
            return searchRFID();
          } catch (refreshError) {
            window.location.replace("/");
            return null;
          }
        } else {
          if (error?.response?.data?.err?.data === "Not Found") {
            showError(`RFID not found!`);
          } else {
            showError(`${error?.response?.data?.err?.data}`);
          }
          setData([]);
        }
      });
  };
  return (
    <div style={{}}>
      <AdminMenuBar />
      <Toast ref={toast}></Toast>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
            gap: "20px",
          }}
        >
          <InputText
            className=" my-2"
            placeholder="Search by member Id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />

          <Button
            label="Search User"
            className="my-2"
            onClick={() => searchRFID()}
          />
        </div>
      </div>
      <div>
        {data?.length? (
             <div>
             <div className="flex flex-column justify-content-evenly align-items-center card-container yellow-container">
               <div
                 style={{
                   width: "100%",
                   alignItems: "center",
                   justifyContent: "center",
                 }}
               >
                 <UserDetails id={id2} />
               </div>
             </div>
           </div>
        ) : (
          <div>No results found!</div>
        )}

      </div>
    </div>
  );
}

export default SearchUser;
