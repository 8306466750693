import React, { useEffect, useState } from "react";
import AdminMenuBar from "../AdminMenuBar";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";

function Election() {
  const [electionData, setElectionData] = useState([]);
  const [code, setCode] = useState("");
  console.log(electionData);
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      label: "Portal Login",
      key: "ms",
    },
    {
      label: "Voting Panel",
      key: "ml",
    },
  ];
  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            setActiveIndex(e.index);
            setElectionData([]);
            navigate("accessCode");
          }
          if (e.index === 1) {
            setActiveIndex(e.index);
            navigate("vote");
          }
        }}
        className="m-3"
        model={items}
      ></TabMenu>

      <div className="text-center m-2 p-2">
        <div className="text-3xl font-bold mt-2">
          {/* {electionCategory.length > 0 && electionCategory} */}
        </div>
      </div>
      <Outlet
        context={{
          electionData,
          code,
          setCode,
          setElectionData,
          setActiveIndex,
        }}
      />
    </div>
  );
}

export default Election;
