import axios from "axios";
const login = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const loginMember = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/memberships/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const logout = async () => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logout`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return logout();
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};

const getAllMembers = async (pageNumber, pageSize, order, url) => {
  let url2 = "";
  if (order !== null) {
    url = `&sortType=MEM_id_no&orderBy=${order}`;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships?pageNumber=${pageNumber}&pageSize=${pageSize}${url2}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}`,
      {
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const toggleStatus = async (id, data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}/toggle?status=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

const register = async (data) => {
  // try {
  // const response =
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  // return response;
  // } catch (error) {
  //   if (error.response.status === 401) {
  //     window.location.replace("/");
  //     return null;
  //   }
  //   return error;
  // }
};

const createNewUser = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

// const generateAccessCode = async (rfid) => {
//   const response = await axios.post(
//     `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/${rfid}`,

//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMSIsImVtYWlsIjoic3VwZXJhZG1pbkBiYXNldGVuYW50LmNvbSIsImZpcnN0X25hbWUiOiJTdXBlciIsImxhc3RfbmFtZSI6IkFkbWluIiwic2lnbl9pbl9jb3VudCI6MCwidGVuYW50Ijp7ImlkIjoiNjI4ZDdjZjYtOWI5NC00NjVhLWI3ZWUtYjU2NDY2MGM0ZWEwIiwidXNlcl9pZCI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMSIsInRlbmFudF9pZCI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMSIsImFjY2VwdGVkX3ZlcnNpb24iOm51bGwsImNyZWF0ZWRfYXQiOiIyMDIyLTA2LTIzVDE1OjE2OjI4LjA0MVoiLCJ1cGRhdGVkX2F0IjoiMjAyMi0wNi0yM1QxNToxNjoyOC4wNDFaIn0sInJvbGVzIjpbImZmMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMCJdLCJpYXQiOjE2NjEyNTEzNzR9.Xnx0fhapTyb8ZF9DMQCUCuXHMVODc629LdurTSCg1bQ`,
//       },
//     }
//   );

//   return response.data;
// };

const getListOfAllCandidates = async (votingCode) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/${votingCode}`,

    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "fkcci-testing",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  return response;
};
const getResults = async (code) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/all?key=${code}`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return getResults();
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};
const getResultDashboard = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/sector`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return getResultDashboard();
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};
const searchExactRfid = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const voteForCandidates = async (id, data, headers) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/${id}`,
      data,
      headers
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};

const generateAccessCode = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/generatecode/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getDistrictResults = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/district`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return getDistrictResults();
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};
const getVoterCount = async (code) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/voterCount/all?key=${code}`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return getVoterCount();
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};

const searchMemberApi = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/members?key=${data}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getMemberDetail = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const uploadMembersImages = async (id, data) => {
  try {
    console.log("data", data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/photo`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const deleteMembersImages = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/photo/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const updateMember = async (data, id) => {
  try {
    const response =
   await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/rfid/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
    return response?.data;
  }  catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return updateMember(data,id);
      } catch (refreshError) {
        window.location.replace("/");
        return error;
      }
    } else{
      return error;
    }
  }
};
const getActivity = async (number,size) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/voting/vote/logs?pageNumber=${number}&pageSize=${size}`,

      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "fkcci-testing",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
          {
            refreshToken: window.localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("token", refreshResponse?.data?.accessToken);
        const newToken = refreshResponse?.data?.accessToken;
        window.localStorage.setItem("token", newToken);
        return getActivity(number,size);
      } catch (refreshError) {
        window.location.replace("/");
        return null;
      }
    }
  }
};
export {
  login,
  loginMember,
  register,
  getAllMembers,
  toggleStatus,
  deleteUser,
  createNewUser,
  searchMemberApi,
  logout,
  generateAccessCode,
  getMemberDetail,
  searchExactRfid,
  getListOfAllCandidates,
  voteForCandidates,
  getResults,
  getResultDashboard,
  getDistrictResults,
  getVoterCount,
  updateMember,
  uploadMembersImages,
  deleteMembersImages,
  getActivity
};
