import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberData: [],
  isRefresh: true,
  total: 0,
  token:null
};

export const memberSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setMemberData: (state, action) => {
      state.memberData = action.payload;
    },
    setRefresh: (state, action) => {
      state.isRefresh = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setToken:(state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setMemberData, setRefresh, setTotal,setToken } = memberSlice.actions;

export default memberSlice.reducer;
