import React, { useEffect, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { login, loginMember } from "../apis/api";
import Logo from "../assets/logo.png";
import { Button } from "primereact/button";
import LogoLeft from "../assets/LoginLeft.png";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/member/memberReducer";
const Login = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [type, setType] = useState("");
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const myToast = useRef(null);
  const userRoleIds = {
    "ff000000-0000-0000-0000-000000000000": "Super Admin",
    "ff000000-0000-0000-0000-000000000009": "Election Scan",
    "ff000000-0000-0000-0000-000000000010": "Election Vote",
    "ff000000-0000-0000-0000-000000000011": "Election Result",
    "ff000000-0000-0000-0000-000000000006": "Membership Office",
    "ff000000-0000-0000-0000-000000000005": "Joint Secretary",
    "ff000000-0000-0000-0000-000000000003": "Secretary",
    "ff000000-0000-0000-0000-000000000002": "Vice President",
    "ff000000-0000-0000-0000-000000000004": "President",
    "ff000000-0000-0000-0000-000000000001": "FKCCI Member",
   'ff000000-0000-0000-0000-000000000013':"Election Activity User",
   'ff000000-0000-0000-0000-000000000014':"Election RFID Update User"
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = "Email is required.";
      }
      // else if (
      //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      // ) {
      //   errors.email = "Invalid email address. E.g. example@email.com";
      // }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },

    onSubmit: async (data) => {
      console.log(process.env);
      console.log("type", type);
      if (type === "Admin") {
        login(data.email, data.password)
          .then((res) => {
            console.log(res);

            if (res.token) {
              window.localStorage.setItem("token", res.token);
              window.localStorage.setItem("refreshToken", res.refreshToken);
              window.localStorage.setItem("user", JSON.stringify(res.user));
              const id = res.user.roles[0];
              const exists = Object.keys(userRoleIds).includes(id);
              dispatch(setToken(res.token));
              if (exists) {
                if (userRoleIds[id] === "Super Admin") {
                  console.log(exists, userRoleIds[id], "jhgfghjh");
                  window.localStorage.setItem("userType", "Super Admin");
                  navigate("/scanRfid");
                }
                if (userRoleIds[id] === "Election Scan") {
                  console.log(exists, userRoleIds[id], "jhgfghjh");
                  window.localStorage.setItem("userType", "Election Scan");
                  navigate("/scanRfid");
                }
                if (userRoleIds[id] === "Election Vote") {
                  console.log(exists, userRoleIds[id], "jhgfghjh");
                  window.localStorage.setItem("userType", "Election Vote");
                  navigate("/election/accessCode");
                }
                if (userRoleIds[id] === "Election Result") {
                  console.log(exists, userRoleIds[id], "jhgfghjh");
                  window.localStorage.setItem("userType", "Election Result");
                  navigate("/results");
                }
                if (userRoleIds[id] === "Election RFID Update User") {
                  window.localStorage.setItem("userType", "Election RFID Update User");
                  navigate("/searchUser");
                }
                if (userRoleIds[id] === "Election Activity User") {
                  window.localStorage.setItem("userType", "Election Activity User");
                  navigate("/activityFeed");
                }
              }
            }
          })
          .catch((res) => {
            console.log(res);
            if (res.response.data === "Unauthorized") {
              showUnAuthorized();
              console.log("UnAuthorized");
            }
          });
      }
      formik.resetForm();
    },
  });

  const showUnAuthorized = (info) => {
    toast.current.show({
      severity: "error",
      summary: info || "UnAuthorized Access",
      // detail: 'Message Content',
      life: 3000,
    });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const header = (
    <div>
      <img src={Logo} alt="Card" />
    </div>
  );

  return (
    <div style={{ height: "100%", display: "flex" }}>
      <Toast ref={toast} />
      <div
        style={{
          background: "white",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {header}
          <div className="">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div
                style={{
                  display: "flex",
                  margin: "20px 0px",
                  justifyContent: "center",
                }}
              >
                <div>
                  <i
                    style={{ fontSize: "30px", margin: "10px" }}
                    className="pi pi-user"
                  ></i>
                </div>

                <div style={{ textAlign: "center", margin: "10px" }}>
                  <div style={{ fontSize: "25px", fontWeight: "700" }}>
                    Sign in to your Account
                  </div>
                </div>
              </div>

              <div className="p-field">
                <InputText
                  id="email"
                  style={{ height: "45px" }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus
                  placeholder="Username or Email Address"
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                  })}
                />
                {getFormErrorMessage("email")}
              </div>

              <div className="p-field">
                <Password
                  style={{ height: "45px" }}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("password"),
                  })}
                  feedback={false}
                />
                {getFormErrorMessage("password")}
              </div>
              <div
                className="col-12"
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Checkbox inputId="cb1" value="Keep me Logged"></Checkbox>
                  <label
                    style={{ marginLeft: "10px" }}
                    htmlFor="cb1"
                    className="p-checkbox-label"
                  >
                    Keep Me Logged In
                  </label>
                </div>
                <strong style={{ color: "#2A2B8F", cursor: "pointer" }}>
                  Forget Password
                </strong>
              </div>

              <Button
                type="submit"
                label="Sign In"
                style={{
                  color: "#2A2B8F",
                  height: "45px",
                }}
                onClick={() => {
                  setType("Admin");
                }}
                className="mt-2"
              />
            </form>
            <div
              style={{
                display: "flex",
                margin: "10px 0px",
                justifyContent: "center",
              }}
            >
              <div style={{ margin: "5px" }}>Don't have an account?</div>
              <strong
                style={{ margin: "5px", color: "#2A2B8F", cursor: "pointer" }}
              >
                Apply for Membership
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "50%", height: "100vh" }}>
        <img src={LogoLeft} alt="" style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  );
};

export default Login;
