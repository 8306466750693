import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AdminMenuBar from "../AdminMenuBar";
import { Divider } from "primereact/divider";
import MemberDescription from "./components/MemberDescription";
import MemberProfile from "../UserManagement/components/MemberDetails/components/MemberProfile";
import useFetch from "../../../Hooks/useFetch";
import { Toast } from "primereact/toast";
import axios from "axios";

function ScanRfid() {
  const [id, setId] = useState("");
  const toast = useRef(null);
  const [rfid, setRfid] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  // const { data, loading, err, statusCode } = useFetch(
  //   "GET",
  //   `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${rfid}`,
  //   update
  // );
  const searchRFID = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "fkcci-testing",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${id}`,
        headers
      )
      .then((res) => {
        console.log("res", res);
        setData(res?.data);
        setRfid(id);
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          try {
            const refreshResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
              { refreshToken: window.localStorage.getItem("refreshToken") },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("token", refreshResponse?.data?.accessToken);
            const newToken = refreshResponse?.data?.accessToken;
            window.localStorage.setItem("token", newToken);
            return searchRFID();
          } catch (refreshError) {
            window.location.replace("/");
            return null;
          }
        } else {
          if (error?.response?.data?.err?.data === "Not Found") {
            showError(`RFID not found!`);
          } else {
            showError(`${error?.response?.data?.err?.data}`);
          }
          setData([]);
        }
      });
  };
  // useEffect(() => {
  //   const headers = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": "fkcci-testing",
  //       Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  //     },
  //   };
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}/search/rfid/${id}`,
  //       headers
  //     )
  //     .then((res) => {
  //       console.log("res", res);
  //       setData(res?.data);
  //     })
  //     .catch((err) => {
  //       // console.log("error", err);
  //       setData([]);
  //     });
  // }, [update]);
  console.log(data);
  return (
    <div style={{}}>
      <AdminMenuBar />
      {/* {statusCode === 401 &&
        showError("UnAuthorized, you will be redirected to the login page")} */}
      <Toast ref={toast}></Toast>
      <div>
        {/* <div style={{ letterSpacing: "10px" }} className="flex flex-column">
              <div className="text-6xl">Search</div>
              <div style={{ letterSpacing: "7px" }} className="text-2xl">
                With RFID{" "}
              </div>
            </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
            gap: "20px",
          }}
        >
          <InputText
            className=" my-2"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          {error && (
            <div className="my-2 text-red-500">
              {" "}
              The RFID must contain 10 Digit Numbers
            </div>
          )}
          <Button
            label="Search With RFID"
            className="my-2"
            onClick={() => searchRFID()}
          />
        </div>
      </div>
      <div
      // className="border-round p-3 flex align-items-center justify-content-evenly"
      >
        {Object.keys(data).length > 0 ? (
          <div>
            <div className="flex flex-column justify-content-evenly align-items-center card-container yellow-container">
              {/* <div className="w-5 border-round-2xl  flex justify-content-center align-items-center align-self-center">
                <MemberProfile
                  status={data[0]?.status}
                  data={data[0]?.media?.slice(0, 4)}
                  size={150}
                />
              </div> */}

              <div
                // className="w-7  border-round-2xl flex justify-content-center align-items-center"
                style={{
                  // display: "flex",
                  // padding: "300px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MemberDescription
                  scanRfid={true}
                  update={update}
                  setUpdate={setUpdate}
                  data={data[0]}
                  rfid={rfid}
                  setAccessCode={setAccessCode}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>No results found!</div>
        )}
      </div>
    </div>
  );
}

export default ScanRfid;
