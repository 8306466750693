import React, { useRef, useState } from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { pdf, Document, Page, View, Text } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
function CandidatesTable() {
  const [layout, setLayout] = useState("grid");
  const toast = useRef(null);
  const printRef = useRef(null);
  const navigate = useNavigate();
  const props = useOutletContext();

  const candidates = props.electionData?.candidates;
  const maximumVotes = props.electionData?.maximum;
  const sector = props.electionData?.category;
  const code = props.code;
  console.log(candidates);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  console.log(selectedCandidates.map((item) => item.id));
  const itemTemplate = (data, layout) => {
    if (layout === "list") {
      return null;
    }

    if (layout === "grid") {
      return renderGridItem(data);
    }
  };
  const showMaximumVoteError = () => {
    toast.current.show({
      severity: "error",
      summary: "Maximum Vote Limit Reached",
      detail: `You Can Vote for Maximum of ${maximumVotes} Peoples`,
      life: 3000,
    });
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Voting Success",
      detail: "Thanks For Voting",
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const handleVote = (data) => {
    if (selectedCandidates.length < maximumVotes) {
      const newData = [...selectedCandidates, data];
      setSelectedCandidates(newData);
    } else {
      showMaximumVoteError();
    }
  };
  const vote = async () => {
    if (selectedCandidates.length < maximumVotes) {
      showError(`You have to select ${maximumVotes} Peoples`);
    }
    //  else {
    //   var myHeaders = new Headers();
    //   myHeaders.append("x-api-key", " fkcci-testing");
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append(
    //     "Authorization",
    //     `Bearer ${window.localStorage.getItem("token")}`
    //   );
    //   var raw = JSON.stringify({
    //     candidateIdArr: selectedCandidates.map((item) => item.id),
    //   });

    //   var requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow",
    //   };

    //   fetch(
    //     `${
    //       process.env.REACT_APP_BACKEND_BASE_URL
    //     }/voting/vote/${code.toLowerCase()}`,
    //     requestOptions
    //   )
    //     .then(async (response) => {
    //       console.log("status", response.status);
    //       if (response.status === 401) {
    //         try {
    //           const refreshResponse = await axios.post(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
    //             {
    //               refreshToken: window.localStorage.getItem("refreshToken"),
    //             },
    //             {
    //               headers: {
    //                 "Content-Type": "application/json",
    //               },
    //             }
    //           );
    //           console.log("token", refreshResponse?.data?.accessToken);
    //           const newToken = refreshResponse?.data?.accessToken;
    //           window.localStorage.setItem("token", newToken);
    //           return vote();
    //         } catch (refreshError) {
    //           window.location.replace("/");
    //           return null;
    //         }
    //       } else {
    //         console.log("res", response);
    //          return response;
    //       }
    //     })
    //     .then((result) => {
    //       console.log(result);
    //       if (result.status === "success") {
    //         showSuccess(
    //           "Thanks for Voting, you will be redirected to Election Portal Page"
    //         );
    //         handleVotePrint();
    //       } else if (result.err.data === "The User has already voted") {
    //         showError("The User has already voted");
    //         setTimeout(() => {
    //           navigate("/election/accessCode");
    //           props.setActiveIndex(0);
    //           props.setElectionData([]);
    //         }, 3000);
    //       } else if (result.err.data.name) {
    //         showError(
    //           "Your Vote is Already Casted, you will be redirected to Election Portal Page"
    //         );
    //         setTimeout(() => {
    //           navigate("/election/accessCode");
    //           props.setActiveIndex(0);
    //           props.setElectionData([]);
    //         }, 3000);
    //       }
    //     })
    //     .catch(async (error) => {
    //       console.log("errssssr", error);
    //       console.log("errssssor", error.response.status);
    //     });
    // }
    else {
      const headers = {
        "x-api-key": "fkcci-testing",
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      };

      const data = {
        candidateIdArr: selectedCandidates.map((item) => item.id),
      };

      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_BACKEND_BASE_URL
          }/voting/vote/${code.toLowerCase()}`,
          data,
          {
            headers: headers,
          }
        );

        // Handle the response here
        console.log("Response:", response.data);
        showSuccess(
          "Thanks for Voting, you will be redirected to Election Portal Page"
        );
        handleVotePrint();
      } catch (error) {
        if (error?.response.status === 401) {
          try {
            const refreshResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
              {
                refreshToken: window.localStorage.getItem("refreshToken"),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("token", refreshResponse?.data?.accessToken);
            const newToken = refreshResponse?.data?.accessToken;
            window.localStorage.setItem("token", newToken);
            return vote();
          } catch (refreshError) {
            window.location.replace("/");
            return null;
          }
        } else {
          showError(error?.response?.data?.err?.data);
        }
     
      }
    }
  };
  const handleRemove = (data) => {
    const filteredArray = selectedCandidates.filter(function (v) {
      return v !== data;
    });
    setSelectedCandidates(filteredArray);
  };
  // const handleVotePrint = useReactToPrint({
  //   // content: () => printRef.current,
  //   // onAfterPrint: () => {
  //   //   navigate("/election/accessCode");
  //   //   props.setActiveIndex(0);
  //   //   props.setElectionData([]);
  //   // },
  // });
  const handleVotePrint = async () => {
    const doc = (
      <Document>
        <Page style={{ alignItems: "center" }} size={"A7"}>
          <View
            style={{
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <View style={{ backgroundColor: "#FFB6C1", padding: "10px 20px" }}>
              <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                FKCCI 2023-24
              </Text>
            </View>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                margin: "10px 0 30px 0",
                textDecoration: "underline",
              }}
            >
              {sector}
            </Text>

            {selectedCandidates?.map((data) => (
              <Text
                style={{
                  fontWeight: "600",
                  letterSpacing: ".3px",
                  margin: "5px 0",
                  fontSize: "12px",
                }}
              >
                {data.user.first_name}
              </Text>
            ))}
          </View>
        </Page>
      </Document>
    );
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `fkcci.pdf`);
    setTimeout(() => {
      navigate("/election/accessCode");
      props.setActiveIndex(0);
      props.setElectionData([]);
    }, 3000);
  };
  const renderGridItem = (data) => {
    console.log(data.media);
    return (
      // <div className="p-col-12 md:col-3 p-2">
      <div className="p-col-12  p-2">
        <div className="product-grid-item card p-3">
          <div className="product-grid-item-content">
            <div
              onClick={() => {
                selectedCandidates.includes(data)
                  ? handleRemove(data)
                  : handleVote(data);
              }}
              className="cursor-pointer"
            >
              <img
                style={{
                  height: "180px",
                  width: "180px",
                  padding: "10px",
                }}
                src={
                  data.media[0]
                    ? data.media[0].link
                    : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                }
                alt={data.name}
              />
            </div>

            <div className="p-1">
              <div
                className="product-name cursor-pointer"
                onClick={() => {
                  selectedCandidates.includes(data)
                    ? handleRemove(data)
                    : handleVote(data);
                }}
              >
                {data.user.first_name || "First Name"}
              </div>
            </div>
          </div>
          <div className="text-center p-1">
            {selectedCandidates.includes(data) ? (
              <Button
                icon={"pi pi-check"}
                style={{ width: "80px" }}
                onClick={() => {
                  handleRemove(data);
                }}
              ></Button>
            ) : (
              <Button
                label={"Vote"}
                style={{ width: "80px" }}
                onClick={() => {
                  handleVote(data);
                }}
              ></Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      {maximumVotes ? (
        <div className="dataview-demo px-5">
          <div className="border-round m-2">
            <div className="flex justify-content-between">
              <h1>{sector}</h1>
              <div className="flex justify-content-center align-items-center">
                <Button
                  type="button"
                  label="Maximum Votes"
                  className="mx-3 p-button-secondary"
                >
                  <Badge size="xlarge" value={maximumVotes}></Badge>
                </Button>
                <Button type="button" label="Vote List" className="mx-3">
                  <Badge
                    size="xlarge"
                    value={selectedCandidates.length}
                  ></Badge>
                </Button>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="w-9 flex align-items-center justify-content-center">
                <div className="card">
                  <DataView
                    value={candidates}
                    layout={layout}
                    itemTemplate={itemTemplate}
                  />
                </div>
              </div>
              <Divider layout="vertical" />
              <div className="w-3 m-2  justify-content-between">
                <div>
                  <h3
                    style={{
                      backgroundColor: "#FFD8B2",
                      color: "#805B36",
                      textTransform: "uppercase",
                      padding: ".25em 1rem",
                      borderRadius: "3px",
                      fontWeight: "700",
                      letterSpacing: ".3px",
                      // margin: "10px",
                    }}
                    className="text-center"
                  >
                    Selected Candidates
                  </h3>
                  {selectedCandidates.length > 0 && (
                    <div>
                      {selectedCandidates.map((data) => (
                        <div
                          className="flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "#ECCFFF",
                            color: "#694382",
                            padding: ".25em 1rem",
                            borderRadius: "3px",
                            fontWeight: "700",
                            letterSpacing: ".3px",
                            margin: "10px 0",
                          }}
                        >
                          <h3>{data.user.first_name}</h3>
                          {/* <i
                            onClick={() => handleRemove(data)}
                            style={{ cursor: "pointer" }}
                            className="pi pi-times-circle pl-4 text-xl"
                          ></i> */}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="text-center mt-2">
                  <Button
                    onClick={() => {
                      vote();
                    }}
                    className="w-full"
                    label="Confirm Vote"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "300px 0",
              }}
              ref={printRef}
            >
              {selectedCandidates.map((data) => (
                <div
                  className="flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "#ECCFFF",
                    color: "#694382",
                    padding: ".25em 1rem",
                    borderRadius: "3px",
                    fontWeight: "700",
                    letterSpacing: ".3px",
                    margin: "10px 0",
                  }}
                >
                  <h2>{data.user.first_name}</h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        "Login with the Access Code to View the Voting Page"
      )}
    </div>
  );
}

export default CandidatesTable;
