import React from "react";
import logo from "../../assets/FKCCI.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { logout } from "../../apis/api";
import { Tag } from "primereact/tag";

function AdminMenuBar() {
  const userType = window.localStorage.getItem("userType");
  const navigate = useNavigate();
  const items = [];
  if (userType === "Super Admin" || userType === "Election Vote") {
    items.push({
      label: "Election",
      icon: "pi pi-fw pi-cog",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/election/accessCode")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    });
  }
  if (userType === "Super Admin" || userType === "Election Scan") {
    items.push({
      label: "Scan RFID",
      icon: "pi pi-fw pi-cog",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/scanRfid")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    });
  }
  if (userType === "Super Admin" || userType === "Election Result") {
    items.push({
      label: "Results",
      icon: "pi pi-fw pi-cog",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/results")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    });
  }
  if (userType === "Super Admin" || userType === "Election RFID Update User") {
    items.push({
      label: "Search Member",
      icon: "pi pi-fw pi-cog",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/searchUser")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    });
  }
  if (userType === "Super Admin" || userType === "Election Activity User") {
    items.push({
      label: "Activity Feed",
      icon: "pi pi-fw pi-cog",
      template: (item, options) => {
        return (
          <div
            onClick={() => navigate("/activityFeed")}
            className="text-white m-3 text-l"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    });
  }
  items.push({
    label: "Logout",
    icon: "pi pi-fw pi-power-off",
    template: (item, options) => {
      return (
        <div
          onClick={() => {
            logout().then((res) => {
              console.log(res);
              if (res.status === 200) {
                window.localStorage.clear();
                navigate("/");
              }
            });
          }}
          className="text-white m-3 text-l"
          style={{ cursor: "pointer", letterSpacing: "1px" }}
        >
          <span>{item.label}</span>
        </div>
      );
    },
  });
  const items2 = [
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      template: (item, options) => {
        return (
          <div
            onClick={() => {
              logout().then((res) => {
                console.log(res);
                if (res.status === 200) {
                  navigate("/cert-of-origin");
                  window.localStorage.clear();
                }
              });
            }}
            className="text-white m-3"
            style={{ cursor: "pointer", letterSpacing: "1px" }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
    },
  ];
  const start = (
    <div className="flex align-items-center justify-content-center">
      <img
        alt="logo"
        src={logo}
        style={{ height: "75px" }}
        className="m-2 mr-4"
      ></img>
    </div>
  );
  const end = (
    <div
      className="h-3rem mx-3 px-3 flex align-items-center justify-content-center"
      style={{
        backgroundColor: "#42506B",
        color: "white",
        padding: ".25em 1rem",
        borderRadius: "3px",
        fontWeight: "700",
        letterSpacing: ".3px",
      }}
    >
      {userType}
    </div>
  );
  return (
    <div>
      <Menubar
        style={{
          display: "flex",
          background: "#091A32",
          borderStyle: "none",
          borderRadius: "0px",
          gap: "150px",
        }}
        className="h-6rem text-white p-0 m-0"
        start={start}
        model={userType === "COO User" ? items2 : items}
        end={end}
        orientation="vertical"
      />
    </div>
  );
}

export default AdminMenuBar;
