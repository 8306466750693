import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function useFetch(request, url, update) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState("");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "fkcci-testing",
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };
  const getResults = async () => {
    try {
      const response = await axios.get(url, headers);
      setData(response.data);
      setStatusCode(response.status);
    } catch (err) {
      setData([]);
      console.log(err);
      setError(err.response);
      setStatusCode(err.response.status);

      if (err.response.status === 401) {
        try {
          const refreshResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
            {
              refreshToken: window.localStorage.getItem("refreshToken"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("token", refreshResponse?.data?.accessToken);
          const newToken = refreshResponse?.data?.accessToken;
          window.localStorage.setItem("token", newToken);

          // Retry the original request after token refresh
          const headers = {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "fkcci-testing",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          };
          const originalResponse = await axios.get(url, headers);
          setData(originalResponse.data);
          setStatusCode(originalResponse.status);
        } catch (refreshError) {
          // window.location.replace("/");
          return null;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (statusCode === 401) {
      }
    }, 3000);
  }, [statusCode]);

  useEffect(() => {
    setLoading(true);
    if (request === "GET") {
      getResults();
    } else if (request === "POST") {
      axios
        .post(url, headers)
        .then((res) => {
          setData(res.data);
          setStatusCode(res.status);
        })
        .catch((err) => {
          setData([]);
          console.log(err);
          setError(err.response);
          setStatusCode(err.response.status);
        })
        .finally(() => setLoading(false));
    } else if (request === "PUT") {
      axios
        .put(url, headers)
        .then((res) => {
          setData(res.data);
          setStatusCode(res.status);
        })
        .catch((err) => {
          setData([]);
          console.log(err);
          setError(err.response);
          setStatusCode(err.response.status);
        })
        .finally(() => setLoading(false));
    } else if (request === "DEL") {
      axios
        .del(url, headers)
        .then((res) => {
          setData(res.data);
          setStatusCode(res.status);
        })
        .catch((err) => {
          setData([]);
          console.log(err);
          setError(err.response);
          setStatusCode(err.response.status);
        })
        .finally(() => setLoading(false));
    }
  }, [request, url, update]);
  return { data, loading, error, statusCode };
}

export default useFetch;
