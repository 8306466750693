import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate, useLocation } from "react-router-dom";
import { getListOfAllCandidates } from "../../../../apis/api";
import { Toast } from "primereact/toast";
import { useOutletContext } from "react-router-dom";
import axios from "axios";

function Popup() {
  const { setElectionData, setCode, setActiveIndex } = useOutletContext();
  const navigate = useNavigate();
  const [votingCode, setVotingCode] = useState("");
  const toast = useRef(null);

  function showError(message) {
    toast.current.show({
      severity: "error",
      summary: "Error Message",
      detail: message,
    });
  }
  function functionToVote() {
    if (votingCode.length < 1) {
      showError("Please Enter the Access Code to Proceed");
    } else {
      getListOfAllCandidates(votingCode.toLocaleLowerCase())
        .then((res) => {
          console.log(res);
          setElectionData(res.data.data);
          setCode(votingCode);
          setActiveIndex(1);
          navigate("/election/vote");
        })
        .catch(async (error) => {
          if (error.response && error.response.status === 401) {
            try {
              const refreshResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/token`,
                {
                  refreshToken: window.localStorage.getItem("refreshToken"),
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              console.log("token", refreshResponse?.data?.accessToken);
              const newToken = refreshResponse?.data?.accessToken;
              window.localStorage.setItem("token", newToken);
              return functionToVote();
            } catch (refreshError) {
              window.location.replace("/");
              return null;
            }
          } else {
            showError(error?.response?.data?.err?.data);
            setCode("");
            setElectionData([]);
          }
        });
    }
  }
  return (
    <div>
      <Toast ref={toast} />
      <div
        style={{ height: "100%" }}
        className="flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{
            backgroundColor: "#ECCFFF",
            color: "#694382",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: "6px",
            margin: "20px",
          }}
          className="text-5xl font-bold"
        >
          FKCCI ELECTION
        </div>
        <div className="p-fluid w-25rem ">
          <h2 className="m-2">Enter Access Code to Proceed</h2>
          <div className="field mt-4">
            <span className="p-float-label">
              <InputText
                id="name"
                name="name"
                value={votingCode}
                onChange={(e) => {
                  setVotingCode(e.target.value);
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    functionToVote();
                  }
                }}
              />
              <label htmlFor="name">Access Code</label>
            </span>
          </div>
          <Button
            onClick={() => {
              functionToVote();
            }}
            label="Proceed"
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default Popup;
