import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { useDownloadExcel } from "react-export-table-to-excel";
import AdminMenuBar from "../AdminMenuBar";
import { getActivity } from "../../../apis/api";
const ActivityFeed = () => {
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState([]);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getActivity(currentPage, 10)
      .then((res) => {
        return res;
      })
      .then((res) => {
        console.log('res',res)
        setData(res?.results);
        setTotalRecords(res?.total);
      });
  }, [currentPage]);
  const date = (data) => {
    return (
      <div>
        <div>{moment(data?.created_at).format("DD MMM YYYY")}</div>
        <div>{moment(data?.created_at).format("h:mm a")}</div>
      </div>
    );
  };
  const title = (data) => {
    return (
      <a
        style={{ cursor: "pointer", color: "#1c7ed6" }}
      >
        {data?.member?.first_name}'s {data?.event_id} by{" "}
        {data?.author?.first_name} {data?.author?.last_name}
      </a>
    );
  };
  const handleMemberExcelPrint = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "FKCCI_Activity_Feed",
    sheet: "Activities",
  });
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">Recent Activity</h2>
      </div>
    );
  };
  const header = renderHeader();
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };
  return (
    <div>
        <AdminMenuBar/>
        <div style={{marginTop:"50px"}}/>
      <div className="card w-9 m-auto">
        <DataTable header={header} value={data} showGridlines>
          <Column field={title} header="" style={{ width: "80%" }} />
          <Column field={date} header="" style={{ width: "20%" }} />
        </DataTable>
        <Paginator
          first={basicFirst}
          totalRecords={totalRecords}
          rows={basicRows}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </div>
    </div>
  );
};

export default ActivityFeed;
